*{
    font-family:'Poppins', sans-serif;
    padding: 0px;
    margin: 0px;
}
p{
    color: #212529;
}
.flex-Arn{
    display: flex;
    justify-content: space-around;
}
.flex-btn{
    display: flex;
    justify-content: space-between;
}
body input[type="email"],
/* body input[type="number"], */
body input[type="password"],
body input[type="confirm-password"],
body input[type="text"],
body input[type="tel"],
body input[type="date"],
body select,
textarea {
    background: transparent;
    border: 0.975853px solid #EAEAF0;
    border-radius: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 100%;
    color: #B4B6CC;
    padding: 16px 20px;
    display: block;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    font-family:"montserrat" , sans-serif; 
}
body .btn,
body button[type="submit"],
body input[type="submit"] {
    background: #5A48FA;
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    padding: 11px 12px 12px;
    min-width: 229px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all;
    border: 2px solid #5A48FA;
    outline: none;
    font-weight: 600;
    font-size: 15.6137px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.0975853px;
    cursor: pointer;
    margin-top: 10px;
    font-family:"montserrat" , sans-serif;  

}

body .btn:hover,
body button[type="submit"]:hover,
body input[type="submit"]:hover {
    background: transparent;
    color: #5A48FA;
    text-decoration: none;
}


body .trans-btn.btn,
body .trans-btn button[type="submit"],
body .trans-btn input[type="submit"] {
    background: transparent;
    color: #5A48FA;
}


body .trans-btn.btn:hover,
body .trans-btn button[type="submit"]:hover,
body .trans-btn input[type="submit"]:hover {
    background: #5A48FA;
    color: #fff;
}

body input[type="email"]:focus,
/* body input[type="number"]:focus, */
body input[type="password"]:focus,
body input[type="confirm-password"]:focus,
body input[type="text"]:focus,
body input[type="tel"]:focus,
textarea:focus {
    border-color: #5A48FA
}

body input::placeholder,
textarea::placeholder {
    color: #B4B6CC
}


textarea {
    resize: none;
    height: 100px;
}

form input[type="submit"] {
    margin-top: 26px;
    display: block;
}

form label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.6619px;
    line-height: 160%;
    letter-spacing: 0.0975853px;
    color: #5E6191;
    margin-bottom: 7px;
    display: block
}
div.formContainer{
    margin: auto;
    max-width: 500px;
    background-color: white;
    padding: 60px;
    margin-bottom: 30vh;
    margin-top: 10vh;
}
.center-text {
    text-align: center;
    margin-top: 10px
  }
  .error {
    color: red;
    margin-bottom: 10px;
    display: block;
  }
div.singleUserContainer{
    width:fit-content;
    margin:20px auto;
    background-color: white;
    padding: 10px;
}

.singleUserContainer p{
    border: 1px solid #022446;
    padding: 8px;
}
.singleUserContainer label{
    color: #05c279;
}
div.singleCategory input[type="text"],
div.singleCategory textarea
{
    width: 90%;
}
/* div.searchBox{
    padding: ;
} */
/* tbody p{
    width: 200px;
} */
