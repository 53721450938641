.left-menu-nav{
    background-color: #022446;
    padding: 10px;
    height: calc(100vh - 20px);
    position: sticky;
    top: 0px;
}
.left-menu-nav p{
    color: #e0e9f2;
    font-size: 11px;
}
