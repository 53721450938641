a{
    text-decoration: none;
    display: block;
    color: white;
    font-size: 15px;
}
table a{
    color: black;
}
a:focus{
    color: white;
}
/* a:hover{
    background-color: #879a9a;
    border-radius: 2px;
} */
.pendinglink{
    background-color: green;
    padding: 20px;
    color: aliceblue;
}
.activelink{
    display: flex;
    gap: 10px;
    background-color: #05c279;
    padding: 10px;
    transition: .2s;
    border-radius: 2px;
    width: 100%;
    margin: 5px;
}
.defaultLink{
    display: flex;
    gap: 10px;
    padding: 10px;
    transition: .2s;
    width: 100%;
    margin: 5px;
}
.defaultLink:hover{
    background-color: #879a9a;
    border-radius: 2px;
}
.linkContainer{
    padding: 0px;
    /* background-color: red;
    margin: 4px 0px; */
    height: 50px;
}
