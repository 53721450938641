.dashboard-block-single{
    background-color: white;
    display: flex;
    padding: 5px;
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
}
.dashboard-block-icon{
    width: fit-content;
    color:white;
    padding: 15px;
    border-radius: 5px;
    margin: 0px 10px;
    display: flex;
    align-items: center;
}
.dashboard-block-text{
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3px 0px;
}
