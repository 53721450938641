.header-div{
    background-color: #0a243e;
    color: white;
    width: 100%;
    height: 60px;
}
.header-inner{
    width:400px;
    display: flex;
    gap: 20px;
}
.header-user-info{
    display: flex;
    gap: 10px;
    justify-items: center;
    align-items: center;
    float: right;
    margin: 10px;
    color: white;
    
}
.header-div p{
    color: white;
    font-weight: 600;
}
.footer-div{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid grey;
    color: #869099;
}
