div.tableContainer{
    padding: 15px;
    margin: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
}

div.tableHeader{
    padding: 30px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
div.tableSorting{
    border-bottom: 1px solid rgba(0,0,0,.125);
}

select{
    padding: 10px;
    margin: 10px 0px;
}
