div p.dashboard-text{
    font-size: 29px;
    margin-bottom: 15px;
}
div.Dashboard-container{
    padding: 15px;
}
div.Earnings-Location-stats{
    margin: 20px 0px;
}
div.Earnings-container{
    background-color: white;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    padding: 0px;
    border-radius: 5px;
}
div.Earnings-header{
    border-bottom: 1px solid grey;
    padding: 15px;
}
div.Earnings-body{
    padding: 15px;
    height: 250px;
}


div.location-stats-container{
    background-color: white;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    padding: 0px;
    border-radius: 5px;
}
div.location-stats-header{
    border-bottom: 1px solid grey;
    padding: 15px;
}
div.location-stats-body{
    padding: 15px;
    height: 250px;
    display: flex;
    justify-content: space-around;
}
div.Latest-data-Container{
    background-color:white;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
}
div.Latest-data-Container span{
    color: #05c279;
}
